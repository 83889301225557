@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.cascader-wrapper {
  .cascader-item {
    margin: 24px 0;
    .cascader {
      min-width: 600px;
      border-bottom: 1px solid #e6ebf0;
    }
  }
}
.upload-select-wrapper {
  .upload-select {
    border-bottom: 1px solid #e6ebf0;
  }
}
