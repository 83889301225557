@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.category-wrapper {
  .category-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .create-category-form {
    .create-category-input-wrapper {
      display: flex;
      .create-category-form-item {
        width: 400px;
        margin-right: 20px;
      }
    }

    .category-tree {
      .category-tree-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .category-name-input {
          width: 400px;
        }
      }
      .tree-item-button {
        margin-left: 40px;
      }
    }

    .create-category-buttons {
      button {
        margin-right: 8px;
      }
    }
  }
}
