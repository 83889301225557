@import './antd-reset/pc/button.scss';
@import './antd-reset/pc/input.scss';
@import './antd-reset/pc/modal.scss';
@import './antd-reset/pc/pagination.scss';
@import './antd-reset/pc/select.scss';
@import './antd-reset/pc/popover.scss';
@import './antd-reset/pc/table.scss';
@import './antd-reset/pc/modal.scss';
@import './antd-reset/pc/button.scss';
@import './antd-reset/pc/datePicker.scss';
@import './antd-reset/pc/card.scss';
@import './antd-reset/pc/form.scss';
@import './antd-reset/pc/tag.scss';
@import './antd-reset/pc/timePicker.scss';

// @import './antd-reset/mobile/button.scss';
// @import './antd-reset/mobile/calendar.scss';
// @import './antd-reset/mobile/checkbox.scss';
// @import './antd-reset/mobile/checklist.scss';
// @import './antd-reset/mobile/mask.scss';
// @import './antd-reset/mobile/modal.scss';
// @import './antd-reset/mobile/tag.scss';
.ant-message {
  z-index: 10000; /* 自定义 z-index 值 */
}
