@import '../../../../../assets/scss/thems.scss';
@import '../../../../../assets/scss/reset.scss';
@import '../../../../../assets/scss/antd-reset.scss';

.content-header {
  .content-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-actions-wrapper {
      .action-button {
        margin-right: 8px;
      }
      .action-divider {
        border-color: #c2cdd6;
        height: 28px;
        margin: 0 20px;
      }
    }
  }
  .content-breadcrumb {
    li {
      color: #0072ef;
      span > a {
        color: #0072ef;
      }
    }
  }
  .collapse-btn {
    margin-right: 16px;
    width: 50px;
  }
  .collapse-icon {
    font-size: 12px;
    cursor: pointer;
    color: #fff;
  }
}
