@import '../../../../assets/scss/thems.scss';
@import '../../../../assets/scss/reset.scss';
@import '../../../../assets/scss/antd-reset.scss';

.drawer {
  padding: 12px 30px;
  .list-item {
    display: flex;
    align-items: center;
    background-color: #f2f5f8;
    height: 48px;
    border-bottom: 1px solid #e6ebf0;
    .item-name {
      width: 240px;
      margin-left: 24px;
    }
    .item-create-time {
      width: 150px;
    }
    .item-suffix,
    .item-create-user {
      width: 90px;
    }
    .item-tags {
      width: 230px;
    }
    .item-download {
      width: 90px;
    }
  }
}

.remove-all-button {
  margin-right: 16px;
}
