@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.home-container {
  .ziess-row {
    overflow: auto;
  }
  .cagetory-content {
    margin-top: 27px;
    &-title {
      line-height: 25px;
      font-size: 18px;
      font-weight: 600;
      color: var(--text-5, #1d2129);
      font-family: PingFang SC;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      .account-search-wrapper {
        width: 368px;
        padding-left: 0;
        .zeiss-input-group-addon {
          display: none;
        }
        .zeiss-input-wrapper {
          border-bottom: 1px solid $grey-21;
        }
        .search-button {
          cursor: pointer;
        }
      }
    }
  }
  .center {
    padding: 20px;
    .bg-container {
      background-color: #ffffff;
      padding: 20px;
    }
  }
  .right {
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
    .bg-container {
      flex: 1;
      background-color: #ffffff;
      margin-bottom: 20px;
      // &:nth-child(1) {
      // }
    }
    .action-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
      padding: 26px 18px;
      margin-bottom: 16px;
      .upload-text {
        font-size: 16px;
        font-weight: 600;
        color: #1d2129;
      }
    }
  }
}
