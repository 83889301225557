@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.zeiss-breadcrumb-wrap {
  padding: 10px 0;
  ol {
    li {
      height: 22px;
      display: flex;
      align-items: center;
    }
  }
  .zeiss-breadcrumb-separator {
    svg {
      position: relative;
      font-size: 22px;
    }
  }
  .breadcrumb-wrap-color {
    color: $blue-primary-5;
    &:hover {
      background: unset;
      color: $blue-primary-5;
    }
  }

  .zeiss-breadcrumb-separator {
    color: $blue-primary-5;
  }
}
