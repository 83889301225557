@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.popover-container {
  padding: 16px 48px;
  .popover-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    .popover-close {
      cursor: pointer;
    }
  }
  .popover-icon {
    margin-bottom: 16px;
  }
  .popover-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .popover-content {
    color: #606a76;
    margin-bottom: 16px;
  }
}
