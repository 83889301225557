@import '../../../../assets/scss/thems.scss';
@import '../../../../assets/scss/reset.scss';
@import '../../../../assets/scss/antd-reset.scss';

.cagetory-card-container {
  display: inline-block;
  width: 100%;
  .zeiss-card {
    border: 1px solid #f0f0f0 !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
    &-head {
      padding: 16px 16px 10px 16px;
      border-bottom: none;
      font-size: 16px;
      color: $grey-20;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
    .cagetory-card-title {
      display: flex;
      .text {
        margin-left: 10px;
      }
    }
    &-body {
      padding: 0 !important;
      .zeiss-tree {
        overflow: auto;
        height: 200px;
        margin: 0 16px 16px 16px;
        background-color: $grey-24;
        padding: 10px;
        color: #606a76;
        font-size: 14px;
        font-weight: 400;
        /* 整个滚动条 */
        &::-webkit-scrollbar {
          width: 3px;
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $grey-25;
          border-radius: 32px;
        }

        &::-webkit-scrollbar-track {
          background-color: $grey-24;
          border-radius: 32px;
        }
      }
    }
  }
}
