@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.permission {
  display: flex;
  .sider {
    width: 240px;
    min-height: calc(100vh - 60px);

    .permission-menu {
      height: 100%;
    }
  }
  .wrapper {
    flex: 1;
  }

  .search-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .header-buttons-wrapper {
      display: flex;
      button {
        margin-left: 8px;
      }
    }
  }

  .role-detail {
    width: 400px;
  }

  .create-role-buttons {
    button {
      margin-right: 8px;
    }
  }

  .role-select {
    border-bottom: 1px solid #e6ebf0;
  }

  .assign-role-wrapper {
    .buttons-wrapper {
      button {
        margin-right: 8px;
      }
    }
  }
}

.role-form {
  max-width: 900px;
  .permission-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .permission-group {
    width: 100%;
    background-color: #f8fafc;
    padding: 16px 24px;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #32373e;
    margin: 16px 0;
    font-weight: 600;
  }
}
