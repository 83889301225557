@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.class-type-tag {
  border-radius: 20px;
  background: #fff;
  padding: 6px 16px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 12px;
  &.cursor {
    cursor: pointer;
  }
  .point {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #fff;
    display: inline-block;
  }
  &.Public {
    background: #1e8565;
  }
  &.Restricted {
    background: #fecb00;
  }
  &.Confidential {
    background: #ec6500;
  }
  &.Secret {
    background: #af2d24;
  }
}
