@import '../../../../../assets/scss/thems.scss';
@import '../../../../../assets/scss/reset.scss';
@import '../../../../../assets/scss/antd-reset.scss';

.view-wrapper {
  .view-content {
    display: flex;
    .file-card {
      width: 144px;
      height: 190px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      :hover {
        background-color: #f2f5f8;
      }
      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
      }
    }
  }
}

.content-table {
  .table-actions {
    display: flex;
    align-items: center;
  }
}

.collapse-button {
  margin-bottom: 16px;
}
