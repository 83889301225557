@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.wrapper {
  .upload-select-wrapper {
    margin-top: 24px;
    .upload-select {
      border-bottom: 1px solid #e6ebf0;
    }
  }
  .user-message-wrapper {
    display: flex;

    .user-select-wrapper {
      width: 60%;
      background-color: #f8fafc;
      border-radius: 8px;
      padding: 24px;
      .select-content {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 24px 0;
        margin-top: 18px;

        .option-item-label {
          .option-icon {
            margin-right: 8px;
          }
        }

        .role-tag {
          background-color: #d5eeff;
        }
      }
    }

    .message-select-wrapper {
      width: 40%;
      background-color: #f8fafc;
      border-radius: 8px;
      padding: 24px;
      margin-left: 24px;
      .select-content {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 24px 0;
        margin-top: 18px;

        .role-tag {
          background-color: #d5eeff;
        }
      }
    }

    .select-title {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .user-select {
    width: calc(100% - 48px);
    border-bottom: 1px solid #c2cdd6;
    margin: 0 24px;
  }
  .selected-user-wrapper {
    margin-top: 24px;
    max-height: 500px;
    overflow-y: auto;
    :hover {
      background-color: #f2f5f8;
    }
    .selected-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 24px;

      .user-info {
        display: flex;
        align-items: center;
        .user-name {
          margin-left: 16px;
        }
      }
    }
  }
  .message-select-wrapper {
    .messaged-user {
      padding: 24px;
      .messaged-user-wrapper {
        margin-top: 24px;
        max-height: 500px;
        overflow-y: auto;
        .user-need-message-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 24px;

          .user-info {
            display: flex;
            align-items: center;
            .user-name {
              margin-left: 16px;
            }
          }
        }
        .user-message-select-all {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color: #fff;
  flex-direction: row-reverse;
  width: 100%;
  .upload-button {
    margin-left: 16px;
  }
}

.upload-container {
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .account-upload-text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .ant-upload-drag-icon {
    margin-bottom: 8px;
  }
}
