@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.vertify-wrapper {
  position: relative;

  .vertify-item {
    width: 100%;
    border-bottom: 1px solid $grey-7;
  }

  .vertify-button {
    position: absolute;
    right: 8px;
    top: 0;
  }

  .vertify-input {
    width: calc(100% - 100px);
  }
}
