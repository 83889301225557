@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.custom-uploader-wrapper {
  width: 100%;
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;

  .custom-upload {
    .zeiss-upload-drag {
      padding: 48px;
      border: 1px solid transparent;
      background: linear-gradient(white, white) padding-box,
        repeating-linear-gradient(
          -45deg,
          #2896fc 0,
          #2896fc 6px,
          white 0,
          white 12px
        );
    }
  }
}

.zeiss-upload-list {
  background-color: #f8fafc;
  padding: 20px 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  .file-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .list {
    border-bottom: 1px solid #e6ebf0;
    background-color: #fff;
  }
  .progress {
    padding-left: 16px;
  }
  .file-list-item {
    display: grid;
    grid-template-columns: 0.59fr 4.95fr 3.98fr 0.48fr;
    li {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      padding-top: 12px;
      .title {
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
      }
      .desc {
        font-size: 12px;
        line-height: normal;
      }
      .dropDown {
        display: inline-flex;
        align-items: center;
      }
      &:first-child {
        align-items: center;
      }
      &:nth-child(2) {
        padding-left: 24px;
      }
      .anticon {
        cursor: pointer;
      }
    }
    // .file-list-item-content {
    //   display: flex;
    //   flex: 1;
    //   flex-direction: column;
    //   border-radius: 8px;
    //   padding: 16px;
    //   margin-left: 16px;
    //   .file-list-info-container {
    //     flex: 1;
    //     display: flex;
    //     // align-items: center;

    //     .file-list-info {
    //       flex: 1;
    //       display: flex;
    //       flex-direction: column;
    //       align-items: start;
    //       justify-content: center;

    //       .file-list-info-name {
    //         font-size: 16px;
    //         font-weight: 600;
    //         color: #32373e;
    //       }
    //       .file-list-info-size {
    //         font-size: 12px;
    //       }
    //       .zeiss-progress {
    //         margin: 0;
    //       }
    //     }
    //   }
    // }
    // .file-list-item-content.item-error {
    //   border: 1px solid red;
    //   background-color: #fdf0f0;
    // }
  }
  .file-list-item-error {
    margin-left: 32px;
    margin-top: 8px;
    color: red;
    font-size: 12px;
  }
}

.zeiss-upload-list:empty {
  display: none;
}

.fileListTableHead {
  display: grid;
  grid-template-columns: 0.59fr 4.95fr 3.98fr 0.48fr;
  border-bottom: 1px solid #c2cdd6;
  background: #ecf0f4;
  li {
    line-height: 47px;
    font-weight: bold;
    &:first-child {
      text-align: center;
    }
    &:nth-child(2) {
      padding-left: 24px;
    }
  }
}
