// reset datePicker
.zeiss-reset-timePicker {
  border-radius: 0;
  border: none;
  background: transparent;
  border-bottom: 1px solid $grey-7;
  box-shadow: none;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    border-color: $grey-7;
  }

  &.zeiss-picker-disabled {
    background-color: transparent;
  }
}
