@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.banner-config-container {
  .banner-config-header {
    display: flex;
    justify-content: space-between;
  }
  .banner-box {
    padding: 20px 10px;
    border-top: 1px solid $grey-19;
    .delete-icon {
      color: $violett-5;
      &:hover {
        color: $violett-5;
      }
    }
    .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
    }
    .img-content {
      margin: 20px 0;
    }
    .banner-link {
      display: inline-block;
      line-height: 32px;
      width: 100%;
      text-align: start;
      vertical-align: top;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      list-style: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $grey-21;
      box-shadow: none;
      &:focus {
        border-bottom-color: $blue-primary-4;
      }
    }
    .link-title {
      font-size: 12px;
      color: $grey-22;
      line-height: 22px;
    }
  }
  .ant-upload-drag-icon {
    text-align: center;
  }
  .ant-upload-tex {
    text-align: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $grey-20;
  }
  .ant-upload-hint {
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $grey-22;
  }
  .zeiss-upload-wrapper {
    display: block;
    height: 202px;
    margin: 18px 0;
  }
  .zeiss-upload-drag {
    border-color: $blue-primary-5;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .zeiss-upload-wrapper .zeiss-upload-drag .zeiss-upload {
    padding: 0;
  }
  img {
    cursor: pointer;
  }
  .cell {
    position: relative;
    .err-tip {
      position: absolute;
      font-size: 12px;
      color: $violett-5;
      bottom: -20px;
      left: 0px;
    }
    .mobile-banner {
      width: 375px;
      height: 210px;
    }
  }
}
