.zeiss-select.zeiss-reset-select {
  .zeiss-select-arrow {
    img {
      width: 100%;
      margin-top: 2px;
    }
  }

  &.zeiss-select-focused {
    .zeiss-select-selector {
      box-shadow: none !important;
    }
  }

  .zeiss-select-selector {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $grey-7;
    background: transparent;
  }

  &.zeiss-select-disabled .zeiss-select-selector {
    background: transparent;
  }
}

.zeiss-select-dropdown {
  border-radius: 0;
  padding: 0;
}
