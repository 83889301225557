@import '../../../../assets/scss/thems.scss';
@import '../../../../assets/scss/reset.scss';
@import '../../../../assets/scss/antd-reset.scss';

.spot-card-container {
  flex: 1;
  .zeiss-card-head {
    padding: 0 16px;
  }
  .zeiss-btn {
    display: flex;
    align-items: center;
    img {
      margin-left: 5px;
    }
  }
  .zeiss-space-item {
    display: flex;
    align-items: center;
  }
  .file-icon {
    width: 20px;
  }
  .file-name {
    font-size: 16px;
  }
  .time {
    font-size: 12px;
    color: $grey-22;
    margin-left: 34px;
  }
  .row-content {
    margin-bottom: 16px;
    cursor: pointer;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}
