@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.hotspot-container {
  .account-table-header {
    display: flex;
    align-items: center;
  }
  .content-breadcrumb {
    color: $blue-primary-5;
    .content-breadcrumb-home {
      cursor: pointer;
      &:hover {
        background-color: $blue-primary-5-100;
      }
    }
  }
  .color-btn {
    margin-left: 8px;
  }
  .danger-btn {
    color: $violett-5;
    border-color: $violett-5;
    margin-left: 8px;
    &:hover {
      color: $violett-5;
      border-color: $violett-5;
    }
  }
  .primary-btn {
    color: $grey-1;
    margin-left: 8px;
  }
  .account-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .account-search-wrapper {
      width: 368px;
      .zeiss-input-group-addon {
        display: none;
      }
      .zeiss-input-wrapper {
        border-bottom: 1px solid $grey-21;
      }
      .search-button {
        cursor: pointer;
      }
    }
  }
  .zeiss-table-thead {
    .zeiss-table-cell {
      background-color: $grey-19 !important;
      border-radius: 0 !important;
    }
  }
  .table-tag {
    color: $grey-20;
  }

  .view-wrapper {
    .view-content {
      display: flex;
      .file-card {
        width: 144px;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        :hover {
          background-color: #f2f5f8;
        }
        .image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 90px;
        }
      }
    }
  }
}

.delete-wrapper {
  width: 480px;
  .delete-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    .delete-buttons {
      button {
        margin-right: 8px;
      }
    }
  }
}

.file-icon {
  svg {
    width: 85px;
    height: 85px;
  }
}
