.zeiss-reset-input {
  border-radius: 0;
  border: none;
  background: transparent;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  .zeiss-input-affix-wrapper,
  .zeiss-form-item-control-input-content,
  .zeiss-input-password,
  .zeiss-input {
    background: transparent;
    box-shadow: none !important;

    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
  }

  &.zeiss-input-border-bottom {
    border-bottom: 1px solid $grey-7;
  }

  &.zeiss-input-status-error.zeiss-input:focus,
  &.zeiss-input-affix-wrapper-focused,
  &.zeiss-input-status-error.zeiss-input-focused {
    outline: none !important;
    box-shadow: none !important;
  }

  &.zeiss-input-disabled {
    background-color: transparent;
  }
}

textarea.zeiss-input {
  border-radius: 0;
}
