@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.detail-page {
  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detail-button {
      margin-left: 8px;
    }
  }
  .detail-content {
    .detail-content-header {
      font-size: 16px;
      font-weight: 600;
      color: #32373e;
    }
    .detail-wrapper {
      .detail {
        margin: 8px 0;
      }
    }
  }

  .content-preview {
    width: 300px;
    height: 300px;
    margin-top: 16px;
  }

  .empty-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.share-file {
  .file-item {
    border-bottom: 1px solid #c2cdd6;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
  }
  .select-wrapper {
    width: 600px;
    .user-select {
      width: calc(100% - 48px);
      border-bottom: 1px solid #c2cdd6;
      margin: 0 24px;
    }
    .selected-user-wrapper {
      margin-top: 24px;
      max-height: 500px;
      overflow-y: auto;
      :hover {
        background-color: #f2f5f8;
      }
      .selected-user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;

        .user-info {
          display: flex;
          align-items: center;
          .user-name {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.admin {
  .admin-content {
    .wrapper {
      .user-select-wrapper {
        width: 600px;
        background-color: #f8fafc;
        border-radius: 8px;
        padding: 24px;

        .custom-tag.role-tag {
          background-color: #d5eeff;
        }
      }
    }
  }
}
