.zeiss-form-item .zeiss-form-item-control {
  overflow: hidden;
}

.zeiss-form-item .zeiss-form-item-label > label {
  color: $grey-10;
  padding-left: 11px;
}
.zeiss-form-item .zeiss-form-item-label .zeiss-form-item-required {
  padding-left: 0;
}
