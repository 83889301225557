$grey-1: #fff;
$grey-2: #f5f7fa;
/* 浅 */
$grey-3: #edf2f7;
$grey-33: #ecf0f4;
$grey-4: #e4ebf2;
$grey-5: #dde3ed;
$grey-6: #d1d9e5;
/* 灰 */
$grey-7: #c1cbd9;
$grey-8: #a5b1c2;
$grey-9: #828d9e;
$grey-10: #626c7a;
$grey-11: #555e6b;
$grey-12: #404952;
$grey-13: #353d45;
/* 深 */
$grey-14: #262e36;
$grey-15: #1a2329;
$grey-16: #161d22;
$grey-17: #07090d;
$grey-18: #738fba;
$grey-19: #e6ebf0;
$grey-20: #32373e;
$grey-21: #c2cdd6;
$grey-22: #606a76;
$grey-23: #cccccc;
$grey-24: #f8fafc;
$grey-25: #d9d9d9;
$grey-26: #f0f0f0;

$blue-primary-1: #a6c9ff;
$blue-primary-1-200: rgba(166, 201, 255, 0.2);
$blue-primary-2: #85b6ff;
$blue-primary-3: #5c9dff;
$blue-primary-4: #2e7af5;
$blue-primary-4-400: rgba(46, 122, 245, 0.4);
$blue-primary-5: #0072ef;
$blue-primary-55: #99c7f9;
/* primary */
$blue-primary-5-100: #e6eefe;
/* primary 0.1透明度 */
$blue-primary-6: #003ecd;
$blue-primary-7: #0029af;

$yellow-1: #ef8354;
/* 蓝紫 */
$violett-1: #8566ff;
$violett-2: #7756fb;
//
$violett-4: #fdf0f0;
$violett-5: #e71e1e;
/* primary */
$violett-3: #6b4beb;
$violett-3-100: #f1edfd;
$violett-3-300: #cfc4f8;
$violett-3-4000: #f9f8fe;

/* 字号 */
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$tr-hover-bg: #eaf7ff;

/* 字体宽度 */
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;

$blue-mune: #5db3ff;

$gray-1: #e6f6ff;
$gray-2: #f5f5f5;

:root {
  --theme-color: $blue-primary-5;
}

:root:root {
  --adm-color-primary: $blue-primary-5;
}

.operationBtn {
  color: $blue-primary-5;
  cursor: pointer;
}

.zeissForm {
  box-sizing: border-box;
  width: 347px;
  max-width: 347px;
  background: $grey-1;
  padding: 24px 32px;

  &-title {
    text-align: center;
    font-size: $font-size-16;
    font-weight: $font-weight-600;
    color: $grey-17;
    line-height: 32px;
  }

  &-form {
    margin-top: 32px;

    .adm-form {
      --border-top: none;
      max-height: 450px;
      overflow: auto;

      .adm-list.adm-list-card {
        margin-left: 0px;
        margin-right: 0px;
      }

      .adm-list-body {
        border: none;
        border-radius: 0;
      }

      .adm-list-body-inner {
        padding-bottom: 20px;
      }

      .adm-list-body {
        border-bottom: 0;
      }

      .adm-form-item {
        padding-left: 0px;
        margin-top: 16px;
        border-bottom: 1px solid $grey-7;

        .adm-list-item-content {
          padding-right: 0px;
          border-top: 0px;

          .adm-list-item-content-prefix {
            padding: 0px;
            width: 80px;

            .adm-form-item-label {
              font-size: $font-size-12;
              font-weight: $font-weight-400;
              line-height: 24px;
              width: 80px;
            }
          }

          .adm-list-item-content-main {
            padding: 0;

            .adm-input-element {
              font-size: $font-size-12;
            }

            .adm-form-item-feedback-error {
              font-size: $font-size-12;
              margin-top: 0;
            }

            .adm-input-disabled {
              opacity: 1;
            }
          }

          .adm-list-item-content-arrow {
            width: 12px;
            color: $blue-primary-5;
            font-size: 20px;
          }
        }
      }

      .adm-form-footer {
        display: flex;
        justify-content: space-between;

        button {
          width: 120px;
        }
      }
    }
  }

  &.zeissForm-card {
    padding: 24px 0px;

    .zeissForm-title {
      padding: 0 32px;
    }

    .zeissForm-form {
      margin-top: 12px;

      .adm-form {
        .adm-list-default {
          padding: 0 32px;
        }

        .adm-list-card {
          padding: 0 20px;

          .adm-list-body {
            padding: 0 6px;
          }
        }
      }

      .adm-form-footer {
        padding: 0 48px;
      }
    }
  }
}

.tag1 {
  background: #fdf0f0;
  color: #500808;
}
.tag2 {
  background: #ffe9e0;
  color: #3e1706;
}
.tag3 {
  background: #fff4d5;
  color: #302300;
}
.tag4 {
  background: #e5f9f3;
  color: #09271e;
}
.tag5 {
  background: #d5eeff;
  color: #32373e;
}
.tag6 {
  background: #e0e8fd;
  color: #32373e;
}
.tag7 {
  background: #f9fcda;
  color: #32373e;
}
.tag8 {
  background: #ecf0f4;
  color: #32373e;
}
.tag9 {
  background: #85a1cc;
  color: #ffffff;
}

.order-status1 {
  background: #fdf1ec;
  color: #3e1706;
}

.order-status2 {
  background: #d5eeff;
  color: #32373e;
}

.order-status3 {
  background: #e5f9f3;
  color: #09271e;
}

.gantt-error {
  color: $violett-5;
  background: $violett-4;
  line-height: 17px;
  padding: 6px 16px 5px 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
