@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.content-aside {
  overflow-y: auto;
  .home-nav {
    display: inline-flex;
    line-height: 40px;
    padding-inline-end: 34px;
    padding-left: 24px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: $gray-2;
    }
  }
  .active {
    color: #606a76;
    background-color: #f2f5f8;
    border-left: 2px solid #32373e;
    &:hover {
      background-color: #f2f5f8;
    }
  }
  .collapse-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding: 16px;
    button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .collapse-icon {
    font-size: 12px;
    cursor: pointer;
    color: #fff;
    display: inline;
  }
  .zeiss-menu-item {
    border-radius: 0;
  }
  .zeiss-menu-item-selected {
    background-color: #f2f5f8;
    &:hover {
      background-color: #f2f5f8;
    }
  }
  .zeiss-menu-submenu-title {
    border-radius: 0;
  }
}
.wrapper-container {
  height: calc(100%);
  overflow-y: auto;
}
