@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.overlay-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1000;
}

.overlay-container.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
