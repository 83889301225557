// reset button
.zeiss-reset-button {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  &.zeiss-btn {
    border-radius: 3px;
  }

  &.zeiss-btn-primary.zeiss-btn[disabled]:not(.zeiss-btn-background-ghost) {
    background: rgba(0, 114, 239, 0.4);
    border-color: transparent;
    color: $grey-1;
  }

  &.zeiss-btn-primary.zeiss-btn[disabled].zeiss-btn-background-ghost {
    background: transparent;
    border-color: rgba(0, 114, 239, 0.4);
    color: rgba(0, 114, 239, 0.4);
  }
  &.zeiss-btn-default {
    // background: $grey-3;
  }

  &.zeiss-btn-primary:not(.zeiss-btn-background-ghost) {
    background-color: $blue-primary-5;
  }

  &.zeiss-btn-background-ghost {
    border-color: $blue-primary-5;
    color: $blue-primary-5;
  }

  &.zeiss-btn-link {
    font-size: 14px;
    font-weight: $font-weight-400;
    color: $blue-primary-5;
  }
}

// zeiss-btn css-dev-only-do-not-override-1p3od3r zeiss-btn-primary zeiss-reset-button
// zeiss-btn css-dev-only-do-not-override-1p3od3r zeiss-btn-primary zeiss-btn-background-ghost zeiss-reset-button
// zeiss-btn css-dev-only-do-not-override-1p3od3r zeiss-btn-link btn-reset

// 禁用button-primary
.zeiss-reset-primary-button-disabled {
  background: $blue-primary-55 !important;
  color: $grey-1 !important;
}
