.zeiss-reset-pagination {
  .zeiss-pagination-item,
  .zeiss-pagination-prev .zeiss-pagination-item-link,
  .zeiss-pagination-next .zeiss-pagination-item-link {
    border-radius: 0;
  }

  .zeiss-pagination-item {
    border: none;
    a {
      color: $grey-22;
      font-size: 14px;
      font-weight: $font-weight-400;
    }
  }

  .zeiss-pagination-item-active {
    border: none;
    border-bottom: 1px solid $grey-20;
    a {
      color: $grey-20;
      font-size: 14px;
      font-weight: $font-weight-600;
    }
  }

  .zeiss-pagination-item,
  .zeiss-select-selector,
  .zeiss-pagination-options-quick-jumper input {
    border-radius: 0;
  }
}
