@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.zeiss-modal-foot-wrap {
  text-align: center;
  .zeiss-modal-foot-button {
    // width: 120px;
    &:last-child {
      margin-inline-start: 16px !important;
    }
  }
}
