@import '../../assets/scss/thems.scss';
@import '../../assets/scss/reset.scss';
@import '../../assets/scss/antd-reset.scss';

.leads-select.arrow-event .ant-select-arrow {
  pointer-events: initial;
}

.select-foot-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.select-dropdown-pop {
  max-width: 300px;
}
