.zeiss-reset-popover {
  padding-top: 0;

  &.zeiss-reset-popover-2 {
    .zeiss-popover-content {
      width: 240px;

      .zeiss-popover-inner {
        border-top: 8px solid $yellow-1;
      }
    }
  }

  .zeiss-popover-content {
    width: 130px;

    .zeiss-popover-arrow {
      display: none;
    }

    .zeiss-popover-inner {
      padding: 0;
      border-radius: 4px;
      line-height: 30px;
      border-top: 8px solid $blue-primary-5;
    }
  }
}
