@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.account-wrapper {
  .account-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .account-actions-wrapper {
      .account-action-button {
        margin-left: 8px;
      }
    }
  }
  .account-table-wrapper {
    .zeiss-pagination,
    .zeiss-select-selection-item {
      font-weight: 500;
    }
    .zeiss-pagination-item {
      border-radius: 3px;
    }
    .active {
      color: #3ad2a3;
      display: inline-flex;
      align-items: center;
    }
    .frozen {
      color: #ed4f4f;
      display: inline-flex;
      align-items: center;
    }
    .dot {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-right: 8px;
    }
    .dot.active {
      background-color: #3ad2a3;
    }
    .dot.frozen {
      background-color: #ed4f4f;
    }
  }
  .account-table {
    .zeiss-table {
      .zeiss-table-thead {
        .zeiss-table-cell {
          background-color: #e6ebf0;
          border-radius: 0;
        }
      }
    }
  }
}

.create-account-wrapper {
  .create-account-form-wrapper {
    margin-top: 8px;
    .create-account-form {
      display: flex;
      flex-direction: column;
      .create-account-form-item {
        width: 400px;
        margin-right: 30px;
      }
      .create-account-input-wrapper {
        display: flex;
      }
    }
  }
  .create-account-buttons {
    button {
      margin-right: 8px;
      span {
        margin-inline-end: 0;
      }
    }
  }
}

.bulk-create-wrapper {
  .content-wrapper {
    width: 860px;
  }
  .upload-header {
    display: flex;
    justify-content: space-between;
  }
  .account-upload-text {
    font-size: 18px;
    font-weight: 500;
    margin-top: 8px;
    color: #32373e;
  }

  .ant-upload-hint {
    font-size: 14px;
    font-weight: 400;
    color: #606a76;
  }

  .bulk-create-dragger-wrapper {
    width: 100%;
    padding: 16px;
    background-color: #f8fafc;
    border-radius: 8px;

    .bulk-create-upload {
      .zeiss-upload-drag {
        padding: 48px;
        border: 1px solid transparent;
        background: linear-gradient(white, white) padding-box,
          repeating-linear-gradient(
            -45deg,
            #2896fc 0,
            #2896fc 6px,
            white 0,
            white 12px
          );
      }
    }
  }

  .table-wrapper {
    margin-top: 20px;
  }
}
