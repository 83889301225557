@import '../../../assets/scss/thems.scss';
@import '../../../assets/scss/reset.scss';
@import '../../../assets/scss/antd-reset.scss';

.history {
  display: flex;
  .sider {
    width: 240px;
    min-height: calc(100vh - 60px);

    .history-menu {
      height: 100%;
    }
  }
  .wrapper {
    flex: 1;
  }
}
