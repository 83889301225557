.zeiss-modal-confirm-reset {
  .ant-modal-content {
    border-radius: 0;
    padding: 16px;

    .ant-modal-confirm-content {
      display: block;
      text-align: center;
      padding-top: 38px;
      padding-bottom: 40px;
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 40px;
      padding-right: 40px;
      border-bottom: 0.5px solid #dde3ed;
    }

    .ant-modal-confirm-btns {
      text-align: center;
      margin-top: 16px;

      button {
        border-radius: 3px;
        // width: 107px;

        &.ant-btn-default {
          margin-right: 15px;
          background: $grey-1;
          color: $blue-primary-5;
          border-color: $blue-primary-5;
        }
      }
    }
  }
}

// reset modal
.zeiss-reset-modal {
  .zeiss-modal-content {
    border-radius: 0 !important;
  }

  .zeiss-modal-title {
    // text-align: center;
    text-align: left; // 修改原因：https://www.kdocs.cn/l/chSbGgATWw3z  第8个 ui bug
    padding-bottom: 18px;
  }
}
