@charset "UTF-8";

* {
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

img {
  border: 0 none;
  vertical-align: top;
}

i,
em {
  font-style: normal;
}

ol,
ul {
  list-style: none;
}

input,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-family: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
a:visited {
  text-decoration: none;
  color: #333;
}

body {
  margin: 0 auto;
  background: $grey-2;
  font-size: 14px;
  font-family: 'Microsoft YaHei', 'PingFang SC', -apple-system, Helvetica,
    sans-serif;
  color: #666;
  -webkit-text-size-adjust: 100% !important;
}

body,
html,
#root,
.App {
  width: 100% !important;
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input.zeiss-input:-webkit-autofill,
input.zeiss-input:-webkit-autofill:hover,
input.zeiss-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
}

.zeiss-theme-tabs {
  .zeiss-tabs-tab-active.zeiss-tabs-tab .zeiss-tabs-tab-btn {
    font-weight: bold;
    color: #1a2329;
  }
}

.zeiss-card-body {
  .zeiss-theme-tabs {
    .zeiss-tabs-tab {
      // padding-top: 0;
    }
  }
}

.zeiss-table-common-hd.zeiss-table-wrapper {
  .zeiss-table-thead > tr > th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.zeiss-form-item-disabled {
  .zeiss-reset-input.zeiss-input-disabled {
    border: none;
    color: $grey-13;
  }
  .zeiss-select.zeiss-reset-select {
    .zeiss-select-selector {
      border: none;
      color: $grey-13;
    }

    .zeiss-select-arrow {
      visibility: hidden;
    }
  }

  .zeiss-reset-datePicker.zeiss-picker-disabled {
    border: none;
    color: $grey-13;

    .zeiss-picker-input > input[disabled] {
      color: $grey-13;
    }
    .zeiss-picker-suffix {
      visibility: hidden;
    }
  }
}
