$tr-hover-bg: #eaf7ff;

.zeiss-table-wrapper tr:nth-child(2n) .zeiss-table-cell-fix-left,
.zeiss-table-wrapper tr:nth-child(2n) .zeiss-table-cell-fix-right {
  background-color: $grey-1;
}

.zeiss-reset-table {
  .zeiss-table {
    border-bottom: 1px solid $violett-3-100;

    .zeiss-table-header {
      .zeiss-table-thead > tr {
        height: 40px;
        background-color: $grey-24;

        & > th {
          color: $grey-22;
          font-size: 12px;
          font-weight: $font-weight-400;
          background-color: inherit;
          border: 1px solid #e6ebf0;
          border-left: 1px;
          border-top: 1px;

          &:not(:last-child):not(.zeiss-table-selection-column):not(
              .zeiss-table-row-expand-icon-cell
            ):not([colspan])::before {
            background-color: transparent;
          }
        }

        &:first-child > th:first-child,
        &:last-child > th:last-child {
          border-radius: 0;
        }
      }
    }

    .zeiss-table-tbody {
      & > tr {
        td {
          height: 44px;
          font-size: 14px;
          font-weight: $font-weight-400;
          border-top-color: transparent;
          border-bottom-color: transparent;
          color: $grey-20;
          border: 1px solid #e6ebf0;
          border-left: 1px;
          border-top: 1px;
        }

        &.zeiss-table-row-selected td {
          background: $tr-hover-bg;
        }

        &:nth-child(2n) {
          background: $grey-1;
        }

        &:hover td.zeiss-table-cell-row-hover,
        &:hover td {
          background-color: $tr-hover-bg;
        }

        & > td.zeiss-table-cell-row-hover:first-child,
        & > td.zeiss-table-cell-row-hover:last-child {
          border-radius: 0 !important;
        }
      }
    }

    .zeiss-checkbox .zeiss-checkbox-inner {
      border-radius: 0;
    }
  }
}

// 睰色导航
.zeiss-reset-table-grey {
  &.zeiss-reset-table-line {
    .zeiss-table {
      .zeiss-table-content {
        .zeiss-table-tbody {
          & > tr {
            td {
              &:first-child {
                background: unset;
              }
            }

            &:nth-child(2n) {
              background: $grey-2;
            }
          }
        }
      }
    }
  }

  .zeiss-table {
    .zeiss-table-content {
      .zeiss-table-thead > tr {
        background-color: #e2e8f1;

        & > th {
          color: $grey-13;
          background-color: inherit;

          &:not(:last-child):not(.zeiss-table-selection-column):not(
              .zeiss-table-row-expand-icon-cell
            ):not([colspan])::before {
            background-color: transparent;
          }
        }

        &:first-child > th:first-child,
        &:last-child > th:last-child {
          border-radius: 0;
        }
      }

      .zeiss-table-tbody {
        > tr.zeiss-table-placeholder {
          &:hover td,
          td {
            background-color: $grey-1 !important;
          }
        }
        & > tr {
          td {
            border-top: 0;
            border-bottom: 1px solid $grey-4;

            &:first-child {
              background: $grey-2;
            }
          }

          &.zeiss-table-row-selected td {
            background: $tr-hover-bg;
          }

          &:hover td.zeiss-table-cell-row-hover {
            background-color: $tr-hover-bg;
          }

          & > td.zeiss-table-cell-row-hover:first-child,
          & > td.zeiss-table-cell-row-hover:last-child {
            border-radius: 0 !important;
          }
        }
      }

      .zeiss-checkbox .zeiss-checkbox-inner {
        border-radius: 0;
      }
    }
  }
}

.zeiss-table-wrapper table {
  border-radius: 0;
}

.zeiss-table {
  // 鼠标hover时候的颜色
  .zeiss-checkbox-wrapper:hover .zeiss-checkbox-inner,
  .zeiss-checkbox:hover .zeiss-checkbox-inner,
  .zeiss-checkbox-input:focus + .zeiss-checkbox-inner {
    border: 1px solid $blue-primary-5;
    background: #edf2f7;
  }
  .zeiss-checkbox-checked {
    // 设置默认的颜色
    .zeiss-checkbox-inner {
      background: #edf2f7;
    }
    // 设置选中的颜色
    .zeiss-checkbox-inner::after {
      border-color: $blue-primary-5;
    }
  }
  .zeiss-table-tbody {
    border-radius: 0 !important;
  }

  .zeiss-table-container {
    border-radius: 0 !important;
  }

  .zeiss-table-wrapper table {
    border-radius: 0 !important;
  }
}

.zeiss-reset-table-lastChild {
  .zeiss-table-thead {
    tr {
      height: 102px;
      th {
        line-height: 80px;
      }
    }
    .zeiss-table-cell {
      &:last-child {
        position: relative;
      }
    }
  }
  .zeiss-table-content {
    table {
      background: $grey-1;
    }
  }
  .zeiss-table-tbody {
    .zeiss-table-cell:last-child {
      position: relative;
    }
  }
}
