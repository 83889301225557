.zeiss-card {
  border: none;
  overflow: hidden;
  border-radius: 0 !important;

  &:not(.zeiss-card-bordered) {
    box-shadow: none;
  }

  .zeiss-card-body {
    padding: 16px;
    border-radius: 0 !important;
  }
}

.zeiss-reset-card {
  .zeiss-card-head {
    background: $grey-3;
    font-size: $font-size-16;
    padding: 6.25px 12px;
    line-height: 22px;
    border-radius: 0;
    .zeiss-card-head-title {
      padding: 0;
    }
  }
}
